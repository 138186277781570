var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('modal',{ref:"modalEditarCliente",attrs:{"titulo":("Actualizar datos del " + (_vm.$config.cliente))},on:{"guardar":_vm.editar_cliente}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-12 text-center mb-4"},[_vm._v(" Seleccione un Avatar ")]),_c('div',{staticClass:"avatar-box rounded-circle cr-pointer"},[_c('img',{attrs:{"src":_vm.model.avatar,"width":"100%","height":"100%","alt":""},on:{"click":function($event){return _vm.seleccionarAvatar()}}})])]),_c('div',{staticClass:"row mx-0 mt-3"},[_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:17","name":"nombres"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Nombres ")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"17","show-word-limit":""},model:{value:(_vm.model.prop_nombre),callback:function ($$v) {_vm.$set(_vm.model, "prop_nombre", $$v)},expression:"model.prop_nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:18","name":"apellidos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Apellidos ")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"18","show-word-limit":""},model:{value:(_vm.model.prop_apellido),callback:function ($$v) {_vm.$set(_vm.model, "prop_apellido", $$v)},expression:"model.prop_apellido"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"password","name":"contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-15 pl-3"},[_vm._v("Contraseña ")]),_c('el-input',{staticClass:"w-100",model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required_if:password|confirmed:password","name":"confirmar contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-15 pl-3"},[_vm._v("Confirmar contraseña ")]),_c('el-input',{staticClass:"w-100",model:{value:(_vm.model.password_confirmation),callback:function ($$v) {_vm.$set(_vm.model, "password_confirmation", $$v)},expression:"model.password_confirmation"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('label',{staticClass:"f-15 pl-3"},[_vm._v(" Fecha Nacimiento ")]),_c('el-date-picker',{attrs:{"type":"date","size":"small","value-format":"yyyy-MM-dd","format":"dd MMM yyyy","clearable":false},model:{value:(_vm.model.nacimiento),callback:function ($$v) {_vm.$set(_vm.model, "nacimiento", $$v)},expression:"model.nacimiento"}})],1),_c('div',{staticClass:"col-12  mt-3"},[_vm._v(" Genero ")]),_c('div',{staticClass:"col-12 d-middle my-3"},[_c('el-radio-group',{model:{value:(_vm.model.genero),callback:function ($$v) {_vm.$set(_vm.model, "genero", $$v)},expression:"model.genero"}},[_c('el-radio',{staticClass:"radio-red",attrs:{"label":3}},[_vm._v(" Femenino ")]),_c('el-radio',{staticClass:"radio-red",attrs:{"label":2}},[_vm._v(" Masculino ")]),_c('el-radio',{staticClass:"radio-red",attrs:{"label":1}},[_vm._v(" Otro ")])],1)],1)])])],1),_c('modal-seleccionar-avatar',{ref:"SelectAvatar",on:{"avatar":_vm.elegir_avatar}}),_c('mapa-google-modal',{ref:"modalMapaGoogle",attrs:{"coordenadas-entrada":_vm.coordenadas},on:{"actualizar":_vm.actualizarCoordenadasCiudad}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }